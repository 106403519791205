var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class Reports {
    async FinancialReports() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/financial_report`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async CenterWiseReport() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/center_base_information`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async PerformanceReport() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/performance_report`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async AllDonorReport() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/all_donors_reports`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };


    async MasterReport() {
        try {
            const response = await axios.request({
                method: "POST",
                url: `${baseUrl}reports/get_all_master_report`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async Monthwisereport() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/statement_of_cornea`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async surgeonwisereport() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}reports/surgeon_wise_report`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    
}