<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong> Collection and Transplant Report</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">


      <!-- <v-row class="mr-5 pt-3 mb-3"> -->
      <!-- Start date template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY" readonly hide-details outlined dense
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_from" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <!-- End data template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY" readonly hide-details outlined dense
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_to" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <!-- <v-col cols="12" md="2">
        <v-btn color="primary" @click="refreshButton()">Refresh</v-btn>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="reportExport()"> Export </v-btn>
      </v-col>
    </v-row> -->


      <!-- <v-col cols="12" md="3">
      <v-autocomplete 
              v-model="filter.date_from"
              :items="YearFrom" 
              outlined dense hide-details
              label="Year From">
          </v-autocomplete>
     </v-col>
     <v-col cols="12" md="3">
      <v-autocomplete   
              v-model="filter.date_to"
              :items="YearTo" 
              outlined dense hide-details
              label="Year To">
          </v-autocomplete>
     </v-col>
      <v-col cols="12" md="3">
      <v-autocomplete   
              v-model="filter.months"
              :items="Monthitem" 
              multiple
              outlined dense hide-details
              label="Month">
          </v-autocomplete>
     </v-col>
     <v-col cols="12" md="3">
      <v-autocomplete   
              v-model="filter.quarter"
              :items="YearT" 
              outlined dense hide-details
              label="Quarter">
          </v-autocomplete>
     </v-col> -->



      <!-- ENd date ui-->

      <v-col cols="12" md="3">
        <v-autocomplete v-model="filter.chapter" :items="BranchesList" multiple outlined dense hide-details
          label="Chapter" :menu-props="{ closeOnClick: true, closeOnContentClick: true }" @change="setChapter" chips
          closable-chips>
          <template v-slot:label>
            <div class="indigo--text">{{ labelChapter }}</div>
          </template>




          <template v-slot:selection="{ item }">
            <span>{{ item }}&nbsp;</span>
          </template>

          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon :color="filter.chapter.length > 0 ? 'indigo darken-4' : ''">{{ iconChapter }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete v-model="filter.type" :items="tpyeitem" multiple outlined dense hide-details label="Type" chips
          :menu-props="{ closeOnClick: true, closeOnContentClick: true }" @change="setType">
          <template v-slot:label>
            <div class="indigo--text">{{ labelType }}</div>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ item }}&nbsp;</span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle1">
              <v-list-item-action>
                <v-icon :color="filter.type.length > 0 ? 'indigo darken-4' : ''">{{ iconType }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete v-model="filter.EDCT" item-text="relation_with_caller_other_name" :disabled="isUpdating"
          :items="Edctitem" outlined dense hide-details label="Name of EDCT" multiple class="mb-3" chips closable-chips>
          <template v-slot:label>
            <div class="indigo--text">{{ labelEDCT }}</div>
          </template>

          <!-- <template v-slot:chip="{ props, item }" >
                <v-chip
                  v-bind="props"
                  :text="item.raw.name"
                  class="mb-2"
                  color="primary"
                  closable
                ></v-chip>  
              </template> -->

          <!-- <template v-slot:selection="{ item }">
          <span>{{ item }}&nbsp;</span>
        </template> -->


          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle2">
              <v-list-item-action>
                <v-icon :color="filter.EDCT.length > 0 ? 'indigo darken-4' : ''">{{ iconEDCT }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3" class="pl-0">
        <v-btn color="primary" class="mr-2" @click.prevent="reportSearch()"> Export</v-btn>
        <v-btn color="primary" @click="refreshbutton()">Reset</v-btn>
      </v-col>

    </v-row>

    <!-- <v-data-table
      :headers="headers"
      :items="FinancialReports"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
    >
      <template #[`item.operate`]="{}">
        <v-btn color="primary" class="mr-3" @click="aa()"> Print </v-btn>
        <v-btn color="error" text @click="vv()"> Delete </v-btn>
      </template>
    </v-data-table> -->

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import EDCTService from '@/service/Donor.service'
import financialService from '@/service/Reports.service'
import CenterService from '@/service/Branches.service'
import CounsellorService from '@/service/Counsellor.service'
import FileSaver from 'file-saver'
import VueJsonToCsv from 'vue-json-to-csv'
import { api } from '@/config/config'
import moment from 'moment'
import { mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from '@mdi/js'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      YearFrom: ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      YearTo: ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      Monthitem: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      BranchesList: [],
      tpyeitem: [],
      allData: [],
      chapterWiseType: '',
      chapterDataFilter: '',
      YearT: [1, 2, 3, 4],
      Edctitem: [],
      aa: '',
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      Reports: [],
      monthReports: [],
      filter: {
        date_from: '',
        date_to: '',
        chapter: '',
        type: '',
        EDCT: '',
      },
      csvLabels: {
        center_name: { title: 'Cernter Name' },
        collection: { title: 'Collection' },

      },
      fileName: 'USER-LIST-' + moment().valueOf(),
      csvListData: [],

      // headers: [
      //   { text: 'Cernter Name', value: 'center_name', width: '250px' },
      //   { text: 'Collection', value: 'collection', width: '250px' },
      //   { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
      //   { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
      //   { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
      //    { text: 'OPERATE', value: 'operate', width: '250px' },
      // ],
    }
  },
  computed: {
    dateFormateTo() {
      return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
    },

    dateFormateFrom() {
      return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
    },


    selectAllChapter() {
      return this.filter.chapter.length === this.BranchesList.length
    },
    selectSomeChapter() {
      return this.filter.chapter.length > 0 && !this.selectAllChapter
    },
    iconChapter() {
      if (this.selectAllChapter) return mdiCloseBox
      if (this.selectSomeChapter) return mdiCheckboxBlankOutline
      return mdiCheckboxBlankOutline
    },
    labelChapter() {
      if (typeof this.filter.chapter === "string") { return "Chapter" }
      return this.filter.chapter.length > 0 ? "Chapter" : "Chapter"
    },


    selectAllType() {
      return this.filter.type.length === this.tpyeitem.length
    },
    selectSomeType() {
      return this.filter.type.length > 0 && !this.selectAllType
    },
    iconType() {
      if (this.selectAllType) return mdiCloseBox
      if (this.selectSomeType) return mdiCheckboxBlankOutline
      return mdiCheckboxBlankOutline
    },
    labelType() {
      if (typeof this.filter.type === "string") { return "Type" }
      return this.filter.type.length > 0 ? "Type" : "Type"
    },


    selectAllEDCT() {
      return this.filter.EDCT.length === this.Edctitem.length
    },
    selectSomeEDCT() {
      return this.filter.EDCT.length > 0 && !this.selectAllEDCT
    },
    iconEDCT() {
      if (this.selectAllEDCT) return mdiCloseBox
      if (this.selectSomeEDCT) return mdiCheckboxBlankOutline
      return mdiCheckboxBlankOutline
    },
    labelEDCT() {
      if (typeof this.filter.EDCT === "string") { return "Name of EDCT" }
      return this.filter.EDCT.length > 0 ? "Name of EDCT" : "Name of EDCT"
    },



  },
  watch: {
    options: {
      handler() {
        // this.GetCenter()
        this.EDCTList()
      },
      deep: true,
    },
  },

  async mounted() {
    // this.GetCenter()
    this.EDCTList()

  },

  methods: {
    remove(item) {
      const index = this.filter.EDCT.indexOf(item.name)
      if (index >= 0) this.filter.EDCT.splice(index, 1)
    },
    toggle() {
      if (this.selectAllChapter) {
        this.filter.chapter = []
        this.Edctitem = []
      } else {
        this.filter.chapter = this.BranchesList
      }
      this.setChapter()
    },

    toggle1() {
      if (this.selectAllType) {
        this.filter.type = []
      } else {
        this.filter.type = this.tpyeitem
      }
      this.setType()
    },

    toggle2() {
      if (this.selectAllEDCT) {
        this.filter.EDCT = []
      } else {
        this.filter.EDCT = this.Edctitem
      }
    },

    refreshbutton() {
      this.filter.date_from = '',
        this.filter.date_to = '',
        this.filter.chapter = '',
        this.filter.type = '',
        this.filter.EDCT = ''
    },

    // async GetCenter() {
    //   const service = new CenterService()
    //   let response = await service.BranchesList()
    //   if (response) {

    //   } else {

    //   }
    // },


    async EDCTList() {
      const service = new CenterService()
      let response = await service.BranchesList()
      if (response) {
        this.allData = response.data
        var valueitem = (response.data).map((d) => d['chapter'])
        var uniqChapter = valueitem.reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
        this.BranchesList = uniqChapter.filter(n => n)
      } else {
        this.allData = []
        this.BranchesList = []
      }
    },

    setChapter() {
      var chapterData = this.filter.chapter.join(',')
      this.chapterWiseType = this.allData.filter(i => chapterData.includes(i.chapter))
      this.chapterDataFilter = this.chapterWiseType.map((d) => d['category_type_of_the_requesting_person'])
      // var uniqType = this.chapterDataFilter.reduce(function(a,b){
      //   if (a.indexOf(b) < 0 ) a.push(b);
      //   return a;
      // },[]);


      var uniqType = ["Eye Bank Staff", "Hospital Staff", "Volunteer"];

      console.log(uniqType);

      this.tpyeitem = uniqType.filter(n => n)
    },

    async setType() {
      // var typeData = this.filter.type.join(',')
      // var typeWiseEdct = this.chapterWiseType.filter( i => typeData.includes(i.category_type_of_the_requesting_person))

      // var edctDataFilter = typeWiseEdct.map((d)=>d['referral_name'])
      // var uniqEdct = edctDataFilter.reduce(function(a,b){
      //   if (a.indexOf(b) < 0 ) a.push(b);
      //   return a;
      // },[]);

      // this.Edctitem = uniqEdct.filter(n => n)

      // all edcts

      const service = new CounsellorService()
      let response = await service.counsellorList()
      if (response) {
        this.allData = response.data
        var valueitem = (response.data).map((d) => d['name'])
        var uniqname = valueitem.reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
        this.Edctitem = uniqname.filter(n => n)
      } else {
        this.allData = []
        this.Edctitem = []
      }


    },



    async reportSearch() {
      if (this.filter.date_from == "" || this.filter.date_from == null) {
        return false,
          this.snackbarText = "Please Fill Date",
          this.snackbar = true
      }
      if (this.filter.date_to == "" || this.filter.date_to == null) {
        return false,
          this.snackbarText = "Please Fill Date",
          this.snackbar = true
      }
      // if(this.filter.months == "" && this.filter.quarter == ""){
      //    return false,
      //   this.snackbarText = "please Select month or quarter",
      //   this.snackbar = true
      // }

      let all_chapters = [];

      (this.filter.chapter).map((item) => {
        let str = item;

        let spl = (str.split("("))[1];
        let center_city = (spl.split(")"))[0];
        all_chapters.push(((str.split("("))[0]).trim());
      })





      this.listLoading = true
      const data = {
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
        chapter: all_chapters,
        type: this.filter.type,
        EDCT: this.filter.EDCT
      }
      try {
        const response = await api.post(`reports/coll_and_trnsp_report`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },

        })
        if (response) {
          this.snackbarText = response.data.message,
            this.snackbar = true
          this.Reports = response.data
          var value = `https://api.ebsr.in${this.Reports.path}`
          FileSaver.saveAs(value, 'collectionAndTransplant');
        } else {
          this.donorReport = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
