var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-space-between mb-0 py-7"},[_c('span',{staticClass:"text-h5"},[_c('strong',[_vm._v(" Collection and Transplant Report")])])]),_c('v-row',{staticClass:"ml-2 mr-2 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filter.date_from,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "date_from", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "date_from", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date : DD-MM-YYYY","readonly":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dateFormateFrom),callback:function ($$v) {_vm.dateFormateFrom=$$v},expression:"dateFormateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.date_from),callback:function ($$v) {_vm.$set(_vm.filter, "date_from", $$v)},expression:"filter.date_from"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save('')}}},[_vm._v("Clear ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filter.date_from)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.filter.date_to,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "date_to", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "date_to", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date : DD-MM-YYYY","readonly":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dateFormateTo),callback:function ($$v) {_vm.dateFormateTo=$$v},expression:"dateFormateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.date_to),callback:function ($$v) {_vm.$set(_vm.filter, "date_to", $$v)},expression:"filter.date_to"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save('')}}},[_vm._v("Clear ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.filter.date_to)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.BranchesList,"multiple":"","outlined":"","dense":"","hide-details":"","label":"Chapter","menu-props":{ closeOnClick: true, closeOnContentClick: true },"chips":"","closable-chips":""},on:{"change":_vm.setChapter},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"indigo--text"},[_vm._v(_vm._s(_vm.labelChapter))])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item)+" ")])]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.filter.chapter.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.iconChapter))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filter.chapter),callback:function ($$v) {_vm.$set(_vm.filter, "chapter", $$v)},expression:"filter.chapter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.tpyeitem,"multiple":"","outlined":"","dense":"","hide-details":"","label":"Type","chips":"","menu-props":{ closeOnClick: true, closeOnContentClick: true }},on:{"change":_vm.setType},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"indigo--text"},[_vm._v(_vm._s(_vm.labelType))])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item)+" ")])]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle1}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.filter.type.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.iconType))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"mb-3",attrs:{"item-text":"relation_with_caller_other_name","disabled":_vm.isUpdating,"items":_vm.Edctitem,"outlined":"","dense":"","hide-details":"","label":"Name of EDCT","multiple":"","chips":"","closable-chips":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"indigo--text"},[_vm._v(_vm._s(_vm.labelEDCT))])]},proxy:true},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle2}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.filter.EDCT.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.iconEDCT))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filter.EDCT),callback:function ($$v) {_vm.$set(_vm.filter, "EDCT", $$v)},expression:"filter.EDCT"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.reportSearch()}}},[_vm._v(" Export")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.refreshbutton()}}},[_vm._v("Reset")])],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }